<template>
  <div style="text-align: left">
    <Card style="height:100%">
      <div class="search-con search-con-top">
        <Input clearable placeholder="输入规则名 搜索" class="search-input" v-model="queryinfo.name"
               @on-enter="namechange"
               @on-change="namechange"/>
        <Button @click="namechange" class="search-btn" type="primary">搜索</Button>
        <slot name="new_btn">
          <Button type="primary" class="search-btn"  @click="showaddmodel=true">新建</Button>
        </slot>
      </div>
      <div>
        <Table border ref="selection" :columns="columns" :data="data" ></Table>
      </div>
      <Modal
          v-model="showaddmodel"
          title="规则内容"
          width="600"
          footer-hide>
        <div class="editor-container">
          <Form ref="addformVali" :model="formValidate" :rules="ruleValidate" :label-width="90">
            <FormItem label="规则名" prop="name">
              <Input v-model.trim="formValidate.name" placeholder="请输入规则名称"></Input>
            </FormItem>
            <FormItem label="规则内容" prop="content">
              <Input v-model="formValidate.content" type="textarea" placeholder="请输入规则内容" :autosize="{minRows: 5}"></Input>
            </FormItem>
            <FormItem>
              <Button @click="handleSubmit('addformVali')" type="primary" >提交</Button>
              <Button @click="cancel" style="position: absolute;left: 15%;">取消</Button>
            </FormItem>
          </Form>
        </div>
      </Modal>

      <Modal
          v-model="showdetailsmodel"
          title="规则内容"
          width="600"
          footer-hide>
        <div class="editor-container">
          <Form ref="formVali" :model="formValidate" :rules="ruleValidate" :label-width="90">
            <FormItem label="规则名" prop="name">
              <Input v-model.trim="formValidate.name" placeholder="请输入规则名称"></Input>
            </FormItem>
            <FormItem label="规则内容" prop="content">
              <Input v-model="formValidate.content" type="textarea" placeholder="请输入规则内容" :autosize="{minRows: 5}"></Input>
            </FormItem>
            <FormItem>
              <Button @click="handleSubmit('formVali')" type="primary" >提交</Button>
              <Button @click="cancel" style="position: absolute;left: 15%;">取消</Button>
            </FormItem>
          </Form>
        </div>
      </Modal>
    </Card>
  </div>
</template>

<script>
import {get,post} from "@/api/http.js";
export default {
  name: "StorageRule",
  data() {
    return {
      showdetailsmodel:false,
      showaddmodel:false,
      queryinfo:{
        name:"",
      },
      formValidate:{
        name:"",
        content:"",
      },
      ruleValidate:{
        name: [{ required: true, message: '规则名不能为空', trigger: 'blur' }],
        content: [{ required: true, message: '内容不能为空', trigger: 'blur' }],
      },
      columns: [
        {type: 'selection', width: 60, align: 'center'},
        {title: '规则名', key: 'name',},
        {
          title: '内容',
          key: 'content',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '操 作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                on: {
                  click: () => {
                    this.showdetails(params.row)
                  }
                }
              }, '详 情')
            ]);
          }
        }

      ],
      data: [],
    }
  },
  methods: {
    putrule(data) {
      post("/etcd/api/v1/rule/name", data)
          .then((resp)=>{
            if (resp.code === 0) {
              this.$Message.success(resp.msg);
              this.cancel()
              this.getdata(this.queryinfo)
            } else {
              this.$Message.error(resp.data);
            }
          })
          .catch((e)=>{
            return e
          })
    },

    showdetails(row) {
      this.showdetailsmodel = true
      this.formValidate.name = row.name
      this.formValidate.content = row.content
    },
    namechange() {},
    getdata(queryinfo) {
      const url = "/etcd/api/v1/rule/list"
      get(url,queryinfo)
          .then((resp)=>{
            this.data = resp["data"];
          }).catch(e=>{
        return e
      })
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.putrule(this.formValidate)
        } else {
          this.$Message.error('请完善表单信息');
        }
      })
    },
    cancel() {
      this.formValidate = {name: "",content: ""}
      this.showdetailsmodel = false
      this.showaddmodel = false
    },
  },
  created() {
    this.getdata(this.queryinfo)
  }
}
</script>

<style lang="less" scoped>
.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}

</style>
